<template>
  <div class="AuxiliaryReading">
    <heade></heade>
    <div class="con_wrap" :style="{
      backgroundImage: 'url(' + require('@/assets/' + backgroud) + ')',
    }">
      <div class="con_box nice-scroll">
        <el-button type="text" icon="el-icon-arrow-left" @click="$router.back(-1)">返回上一页</el-button>
        <div class="text" v-html="keyProject.content"></div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import "@css/ico.css";
import "@css/foot.css";
import $ from "jquery";
import { scroll } from "@utils/scroll";
import heade from "@/components/heade.vue";
import foot from "@/components/foot.vue";
import { getKeyProject } from "@api/public";
export default {
  components: {
    heade,
    foot,
  },
  data() {
    return {
      keyProject: {},
      backgroud: "",
    };
  },
  mounted: function () {
    let that = this;
    getKeyProject(that.queryWhere).then(function (res) {
      that.$set(that, "keyProject", res.data);
    });
    scroll($(".nice-scroll"));
  },
  created: function () {
    this.queryWhere = this.$route.query;
    this.backgroud = this.$route.meta.background;
  },
  destroyed: function () {
    $(".nice-scroll").getNiceScroll().hide();
    $(".nicescroll-cursors").hide();
    $(".nicescroll-rails").remove();
  },
};
</script>

<style>
.AuxiliaryReading .con_wrap {
  width: 100%;
  height: 1280px;
  background-size: cover;
  background-position: center 0;
}

.AuxiliaryReading .con_box {
  width: 1314px;
  height: 914px;
  position: relative;
  top: 200px;
  padding-bottom: 50px;
  margin: 0 auto;
  box-sizing: border-box;
}

.el-button--text {
  color: rgb(121, 0, 0);
}

.con_box img {
  width: 80% !important;
  text-align: center !important;
}
</style>